import { Login, LoginForm } from "react-admin"
import { Divider, Typography } from "@mui/material"

const CustomLoginForm = (props: any) => (
  <div>
    <Typography variant="body1" align="center">
      <img
        src="https://archeide.eu/wp-content/uploads/2020/03/logo_archeide_bianco.png"
        alt="AVATAR"
        width={150}
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: 10,
          backgroundColor: "#000",
          borderRadius: 20,
        }}
      />
    </Typography>
    <Typography variant="h6" align="center" gutterBottom={true}>
      Console Amministrazione
    </Typography>
    <Divider variant="middle" />
    <LoginForm {...props} />
  </div>
)

const LoginPage = (props: any) => (
  <Login {...props} backgroundImage={process.env.PUBLIC_URL + "/mountain-bg.png"}>
    <CustomLoginForm {...props} />
  </Login>
)

export default LoginPage
