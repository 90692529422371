import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "react-admin"
import { stringify } from "querystring"
import { getPerformance, trace } from "firebase/performance"
import { initializeApp } from "firebase/app"
import firebaseSetup from "../services/Firebase"

const fetchJson = (url: string, options: { [index: string]: any } = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  // add your own headers here
  options.headers.set("X-Custom-Header", "foobar")
  return fetchUtils.fetchJson(url, options)
}

export default (apiUrl: string) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (
    type: string,
    resource: string,
    params: { [index: string]: any }
  ) => {
    let url = ""
    const options: { [index: string]: any } = {}
    const specialParams = ["pagination", "sort", "filter"]
    const token = localStorage.getItem("token")
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const token = localStorage.getItem("token")
        const query: { [index: string]: any } = {}
        query["where"] = { ...params.filter }
        if (field) query["order"] = [field + " " + order]
        if (perPage >= 0) query["limit"] = perPage
        if (perPage > 0 && page >= 0) query["skip"] = (page - 1) * perPage

        Object.keys(params).forEach((key) => {
          if (!specialParams.includes(key) && params[key] !== undefined) query[key] = params[key]
        })
        url = `${apiUrl}/${resource}?${stringify({
          filter: JSON.stringify(query),
          access_token: token,
        })}`
        break
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}?access_token=${token}`
        break
      case GET_MANY: {
        const listId = params.ids.map((id: string | number) => {
          return { id }
        })

        let query = ""
        if (listId.length > 0) {
          const filter = {
            where: { or: listId },
          }
          query = `?${stringify({ filter: JSON.stringify(filter), access_token: token })}`
        }
        url = `${apiUrl}/${resource}${query}`
        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query: { [index: string]: any } = {}
        query["where"] = { ...params.filter }
        query["where"][params.target] = params.id
        if (field) query["order"] = [field + " " + order]
        if (perPage >= 0) query["limit"] = perPage
        if (perPage > 0 && page >= 0) query["skip"] = (page - 1) * perPage

        Object.keys(params).forEach((key) => {
          if (!specialParams.includes(key) && params[key] !== undefined) query[key] = params[key]
        })

        url = `${apiUrl}/${resource}?${stringify({
          filter: JSON.stringify(query),
          access_token: token,
        })}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}?access_token=${token}`
        options.method = "PATCH"
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = `${apiUrl}/${resource}?access_token=${token}`
        options.method = "POST"
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}?access_token=${token}`
        options.method = "DELETE"
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (
    response: { [index: string]: any },
    type: string,
    resource: string,
    params: { [index: string]: any }
  ) => {
    const { headers, json } = response
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has("content-range")) {
          throw new Error(
            "The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
          )
        }
        return {
          data: json,
          total: parseInt(headers.get("content-range").split("/").pop(), 10),
        }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      case DELETE:
        return { data: { ...json, id: params.id } }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type: string, resource: string, params: { [index: string]: any }) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id: string | number) =>
          fetchJson(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id: string | number) =>
          fetchJson(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params)

    const t = trace(firebaseSetup.perf, `Backend Request`)

    t.start()

    return fetchJson(url, options).then((response) => {
      try {
        t.putAttribute(
          "url",
          new Date().toISOString() +
            " - " +
            url.split("?")[0] +
            " - " +
            type +
            " - " +
            response.status.toString()
        )
      } catch (e) {
        console.error(e)
      }
      t.stop()
      return convertHTTPResponse(response, type, resource, params)
    })
  }
}
