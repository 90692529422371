import {
  Create,
  CreateProps,
  FileField,
  FileInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin"
import { Grid } from "@mui/material"
import ArticleInput from "../../../atoms/ArticleInput"

export const MultimediaCreate = (props: CreateProps) => {
  const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject

      reader.readAsDataURL(file.rawFile)
    })

  return (
    <Create
      {...props}
      redirect="show"
      transform={async (data: any) => {
        console.log(data.aws_file)
        return {
          ...data,
          aws_file: {
            data: await convertFileToBase64(data.aws_file),
            name: data.aws_file.rawFile.name,
          },
        }
      }}
    >
      <SimpleForm>
        <Grid container columnSpacing={2}>
          <Grid item xs={7}>
            <TextInput source="title" fullWidth label="Titolo" validate={required()} />
          </Grid>
          <Grid item xs={5}>
            <ReferenceInput source="category_ID" reference="Categories" validate={required()}>
              <SelectInput color="secondary" optionText="name" fullWidth label="Categoria" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="description"
              fullWidth
              label="Descrizione"
              multiline
              minRows={5}
              validate={required()}
            />
          </Grid>
          <Grid item xs={3}>
            <FormDataConsumer>
              {({ formData }) => (
                <SelectInput
                  source="type"
                  fullWidth
                  label="Tipologia"
                  choices={[
                    { name: "Video", id: "video" },
                    { name: "Audio", id: "audio" },
                  ]}
                  validate={required()}
                  disabled={!!formData.aws_file}
                />
              )}
            </FormDataConsumer>
          </Grid>

          <Grid item xs={9}>
            <FormDataConsumer>
              {({ formData }) => {
                console.log(formData.type)
                return (
                  <FileInput
                    source="aws_file"
                    label="File"
                    validate={required()}
                    accept={`${formData.type}/*`}
                    multiple={false}
                    //                  disabled={!formData.type}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                )
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={7}>
            <ArticleInput />
          </Grid>
          <Grid item xs={5}>
            <TextInput source="imageUrl" fullWidth label="Immagine" disabled />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
