import { ReferenceInput, required, SelectInput, useDataProvider } from "react-admin"
import { useFormContext } from "react-hook-form"

const ArticleInput = (props: any) => {
  const dataProvider = useDataProvider()

  const { setValue } = useFormContext()
  return (
    <ReferenceInput
      source="wordpress_ID"
      reference="Articles"
      validate={required()}
      {...props}
      sx={{ marginTop: 0 }}
    >
      <SelectInput
        color="secondary"
        fullWidth
        optionValue="id"
        label="Articolo collegato"
        optionText={(choice) => `${choice.date.slice(0, 10)}: ${choice.title.rendered}`}
        onChange={async (event) => {
          if (event.target.value) {
            dataProvider
              .getOne("Articles", { id: event.target.value })
              .then(({ data }) => setValue("imageUrl", data.image))
          }
        }}
      />
    </ReferenceInput>
  )
}

export default ArticleInput
