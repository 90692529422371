import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { Title, useGetList } from "react-admin"

const Dashboard = () => {
  const { isLoading: userLoading, total: totalUsers } = useGetList("Clients", {
    pagination: { perPage: 5000, page: 1 },
  })
  const { isLoading: multimediaLoading, total: totalMultimedia } = useGetList("Multimedia", {
    pagination: { perPage: 5000, page: 1 },
  })
  const { isLoading: reactionLoading, total: totalReactions } = useGetList("Reactions", {
    pagination: { perPage: 5000, page: 1 },
  })
  const { isLoading: sessionLoading, total: totalSessions } = useGetList("PlaySessions", {
    pagination: { perPage: 5000, page: 1 },
  })
  return (
    <Box p={"1em"}>
      <Title title="ra.page.dashboard" />
      <Grid container spacing="1em">
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="body2" color="text.secondary">
                Utenti registrati
              </Typography>
              <Typography variant="h5" component="div">
                {!userLoading ? totalUsers : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="body2" color="text.secondary">
                Contenuti presenti
              </Typography>
              <Typography variant="h5" component="div">
                {!multimediaLoading ? totalMultimedia : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="body2" color="text.secondary">
                Sessioni di ascolto presenti
              </Typography>
              <Typography variant="h5" component="div">
                {!sessionLoading ? totalSessions : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="body2" color="text.secondary">
                Reazioni presenti
              </Typography>
              <Typography variant="h5" component="div">
                {!reactionLoading ? totalReactions : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard
