import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"
import { AvatarField } from "../../../atoms/AvatarField"
import Title from "../../../atoms/Title"

export const CategoryShow = (props: ShowProps) => {
  return (
    <Show {...props} title={<Title property="name" title="Categoria" />}>
      <TabbedShowLayout>
        <Tab label="Dati">
          <TextField source="name" label="Titolo" />

          <TextField source="description" label="Descrizione" />
        </Tab>
        <Tab label="Contenuti">
          <ReferenceManyField
            reference="Multimedia"
            target="category_ID"
            label={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <AvatarField />
              <TextField source="title" label="Titolo" />
              <TextField source="type" label="Tipologia" />
              <DateField showTime source="createdAt" label="Data" />
              <ReferenceField
                source="category_ID"
                reference="Categories"
                link="show"
                label="Categoria"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
