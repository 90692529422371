import { Edit, EditProps, SimpleForm, TextInput } from "react-admin"
import Title from "../../../atoms/Title"

const CategoryEdit = (props: EditProps) => {
  return (
    <Edit {...props} redirect="show" title={<Title property="name" title="Categoria" />}>
      <SimpleForm>
        <TextInput source="name" fullWidth label="Titolo" />
        <TextInput source="description" fullWidth label="Descrizione" multiline minRows={5} />
      </SimpleForm>
    </Edit>
  )
}
export default CategoryEdit
