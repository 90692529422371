import { Datagrid, Filter, List, ListProps, SearchInput, SelectInput, TextField } from "react-admin"

export const CategoryList = (props: ListProps) => {
  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      filterDefaultValues={{ isDeleted: false }}
      filters={
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
          <SelectInput
            source="isDeleted"
            fullWidth
            alwaysOn
            label="Mostra eliminati"
            choices={[
              { name: "Si", id: true },
              { name: "No", id: false },
            ]}
          />
        </Filter>
      }
      exporter={false}
    >
      <Datagrid
        rowClick="show"
        rowStyle={(record: Record<string, any>, index: number) => ({
          backgroundColor: record.isDeleted ? "#c4c4c4" : "white",
          textDecoration: record.isDeleted ? "line-through" : "none",
        })}
        isRowSelectable={(record) => !record.isDeleted}
      >
        <TextField source="name" label="Titolo" />
        <TextField source="description" label="Descrizione" />
      </Datagrid>
    </List>
  )
}
