import { getPerformance } from "firebase/performance"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyDj6URJXfOKZtIzgKMhEoiw3rsJQ9taG5g",
  authDomain: "archeide-app.firebaseapp.com",
  projectId: "archeide-app",
  storageBucket: "archeide-app.appspot.com",
  messagingSenderId: "570451957953",
  appId: "1:570451957953:web:6b57d980960a629b79dc40",
  measurementId: "G-GZG6RSDT43",
})

const firebaseSetup = { perf: getPerformance(app), analytics: getAnalytics(app) }
export default firebaseSetup
