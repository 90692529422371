import { Button, useRecordContext, useUpdate } from "react-admin"
import HowToRegIcon from "@mui/icons-material/HowToReg"

const ActivateUserButton = () => {
  const record = useRecordContext()
  const [update, { isLoading, error }] = useUpdate("Clients", {
    id: record.id,
    data: { emailVerified: true, verificationToken: null },
    previousData: record,
  })
  return !record ? null : (
    <Button
      label="Attiva utente"
      disabled={record?.emailVerified}
      onClick={() => {
        update()
      }}
    >
      <HowToRegIcon />
    </Button>
  )
}

export default ActivateUserButton
