import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin"

export const PlaySessionList = (props: ListProps) => {
  return (
    <List
      {...props}
      title="Lista di Sessioni di ascolto"
      sort={{ field: "startDate", order: "DESC" }}
      filters={
        <Filter {...props}>
          <ReferenceInput
            source="client_ID"
            reference="Clients"
            alwaysOn
            sort={{ field: "surname", order: "ASC" }}
          >
            <SelectInput
              color="secondary"
              optionText={(record) => record.surname + " " + record.name}
              fullWidth
              label="Cliente"
            />
          </ReferenceInput>
          <ReferenceInput
            source="multimedia_ID"
            reference="Multimedia"
            alwaysOn
            sort={{ field: "title", order: "ASC" }}
          >
            <SelectInput color="secondary" optionText="title" fullWidth label="Contenuto" />
          </ReferenceInput>
          <SelectInput
            source="status"
            fullWidth
            alwaysOn
            label="Evento"
            choices={[
              { name: "Prossimo", id: "next" },
              { name: "Precedente", id: "previous" },
              { name: "Pausa", id: "pause" },
            ]}
          />
        </Filter>
      }
      bulkActionButtons={<></>}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <DateField source="startDate" label="Data" showTime />
        <ReferenceField label="Cliente" source="client_ID" reference="Clients" link="show">
          <FunctionField
            label="Nome"
            render={(record: any) => `${record["name"]} ${record["surname"]}`}
          />
        </ReferenceField>
        <ReferenceField source="multimedia_ID" reference="Multimedia" link="show" label="Contenuto">
          <TextField source="title" />
        </ReferenceField>
        <FunctionField
          render={(record: RaRecord) => record.startPoint + " - " + record.endPoint}
          label="Tempistiche"
        />
        <TextField source="status" label="Evento" />
      </Datagrid>
    </List>
  )
}
