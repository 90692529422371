import { Typography } from "@mui/material"
import { AppBar, AppBarProps } from "react-admin"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "40px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
})
const CustomAppBar = ({ ...props }: AppBarProps) => {
  console.log()
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" className={classes?.title} id="react-admin-title" />
      <span className={classes?.spacer} />
      <img
        src="https://archeide.eu/wp-content/uploads/2020/03/logo_archeide_bianco.png"
        alt="logo"
        className={classes?.logo}
      />
    </AppBar>
  )
}

export default CustomAppBar
