import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  Filter,
  List,
  ListProps,
  SearchInput,
  TextField,
} from "react-admin"

export const ClientList = (props: ListProps) => {
  return (
    <List
      {...props}
      title="Lista di Clienti"
      filters={
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
        </Filter>
      }
      sort={{ field: "surname", order: "ASC" }}
      bulkActionButtons={<></>}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField label="Cognome" source="surname" emptyText="-" />
        <TextField label="Nome" source="name" emptyText="-" />
        <EmailField label="Email" source="email" emptyText="-" />
        <DateField showTime label="Data iscrizione" source="createdAt" emptyText="-" />
        <BooleanField label="Abilitato" source="emailVerified" emptyText="-" />
      </Datagrid>
    </List>
  )
}
