import { createTheme } from "@mui/material/styles"
import red from "@mui/material/colors/red"

const CustomTheme = createTheme({
  palette: {
    primary: {
      500: "#081C47",
    },
    secondary: {
      A400: "#081C47",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {},
})

export default CustomTheme
