import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin"
import { Grid, Typography } from "@mui/material"

export const ReactionShow = (props: ShowProps) => {
  return (
    <Show {...props} title="Reazione">
      <SimpleShowLayout>
        <Grid container direction="row" justifyContent="center" alignContent="center" spacing={3}>
          <Grid container direction="column" item spacing={3} justifyContent="center">
            {/*}   <Grid item>
                            <Typography>Descrizione</Typography>
                            <TextField label="Descrizione" source="description" color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography>Valutazione</Typography>
                            <TextField label="Valutazione" source="rating" color="secondary" />
                        </Grid>*/}
            <Grid item>
              <Typography>Data</Typography>
              <DateField label="Data" source="date" color="secondary" />
            </Grid>
            <Grid item>
              <Typography>Cliente</Typography>
              <ReferenceField label="Cliente" source="client_ID" reference="Clients" link={false}>
                <FunctionField
                  label="Nome"
                  render={(record: any) => `${record["name"]} ${record["surname"]}`}
                />
              </ReferenceField>
            </Grid>

            <Grid item>
              <Typography>Multimedia ID</Typography>
              <ReferenceField source="multimedia_ID" reference="Multimedia" link={false}>
                <TextField source="id" />
              </ReferenceField>
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  )
}
