import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin"

export const ReactionList = (props: ListProps) => {
  return (
    <>
      <List
        {...props}
        title="Lista di reazioni"
        sort={{ field: "date", order: "DESC" }}
        filters={
          <Filter {...props}>
            <ReferenceInput
              source="client_ID"
              reference="Clients"
              alwaysOn
              sort={{ field: "surname", order: "ASC" }}
            >
              <SelectInput
                color="secondary"
                optionText={(record) => record.surname + " " + record.name}
                fullWidth
                label="Cliente"
              />
            </ReferenceInput>
            <ReferenceInput
              source="multimedia_ID"
              reference="Multimedia"
              alwaysOn
              sort={{ field: "title", order: "ASC" }}
            >
              <SelectInput color="secondary" optionText="title" fullWidth label="Contenuto" />
            </ReferenceInput>
          </Filter>
        }
        bulkActionButtons={<></>}
        exporter={false}
      >
        <Datagrid>
          <DateField label="Data" source="date" showTime />
          <ReferenceField label="Cliente" source="client_ID" reference="Clients" link="show">
            <FunctionField
              label="Nome"
              render={(record: any) => `${record["name"]} ${record["surname"]}`}
            />
          </ReferenceField>
          <ReferenceField
            source="multimedia_ID"
            reference="Multimedia"
            link="show"
            label="Contenuto"
          >
            <TextField source="title" />
          </ReferenceField>
        </Datagrid>
      </List>
    </>
  )
}
