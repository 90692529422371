import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin"
import { Grid } from "@mui/material"

export const PlaySessionShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <Grid container>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <DateField source="startDate" label="Data" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="startPoint" label="Punto iniziale" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="endPoint" label="Punto finale" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <ReferenceField
              label="Podcast"
              source="multimedia_ID"
              reference="Multimedia"
              link={false}
            >
              <TextField source="id" />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout>
            <TextField source="status" label="Evento" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <ReferenceField label="Cliente" source="client_ID" reference="Clients" link={false}>
              <FunctionField render={(record: any) => `${record["name"]} ${record["surname"]}`} />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  )
}
