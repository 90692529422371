import {
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin"
import { Grid } from "@mui/material"
import ActivateUserButton from "../../../atoms/ActivateUserButton"

export const ClientShow = (props: ShowProps) => {
  return (
    <Show
      {...props}
      title="Scheda cliente"
      actions={
        <TopToolbar>
          <ActivateUserButton />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab label="Informazioni">
          <Grid container>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="name" label="Nome" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="surname" label="Cognome" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="email" label="Email" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="profilePhoto" label="Immagine profilo" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="Reazioni">
          <ReferenceManyField
            reference="Reactions"
            target="client_ID"
            label={false}
            sort={{ field: "date", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <DateField label="Data" source="date" showTime />
              <ReferenceField
                source="multimedia_ID"
                reference="Multimedia"
                link="show"
                label="Contenuto"
              >
                <TextField source="title" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Sessioni">
          <ReferenceManyField
            reference="PlaySessions"
            target="client_ID"
            label={false}
            sort={{ field: "startDate", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <DateField label="Data" source="startDate" showTime />
              <ReferenceField
                source="multimedia_ID"
                reference="Multimedia"
                link="show"
                label="Contenuto"
              >
                <TextField source="title" />
              </ReferenceField>
              <FunctionField
                render={(record: RaRecord) => record.startPoint + " - " + record.endPoint}
                label="Tempistiche"
              />
              <TextField source="status" label="Evento" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
