import { useRecordContext } from "react-admin"

const Title = ({ property, title }: { property: string; title: string }) => {
  const record = useRecordContext()
  // the record can be empty while loading
  if (!record) return null
  return (
    <span>
      {title}: {record[property]}
    </span>
  )
}

export default Title
