import {
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin"
import { Grid } from "@mui/material"
import Title from "../../../atoms/Title"
import ArticleInput from "../../../atoms/ArticleInput"

export const MultimediaEdit = (props: EditProps) => {
  return (
    <Edit {...props} redirect="show" title={<Title property="title" title="Contenuto" />}>
      <SimpleForm>
        <Grid container columnSpacing={2}>
          <Grid item xs={7}>
            <TextInput source="title" fullWidth label="Titolo" validate={required()} />
          </Grid>
          <Grid item xs={5}>
            <ReferenceInput source="category_ID" reference="Categories" validate={required()}>
              <SelectInput color="secondary" optionText="name" fullWidth label="Categoria" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="description"
              fullWidth
              label="Descrizione"
              multiline
              minRows={5}
              validate={required()}
            />
          </Grid>

          <Grid item xs={7}>
            <ArticleInput />
          </Grid>
          <Grid item xs={5}>
            <TextInput source="imageUrl" fullWidth label="Immagine" disabled />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
