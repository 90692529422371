import { Create, CreateProps, SimpleForm, TextInput } from "react-admin"

export const CategoryCreate = (props: CreateProps) => {
  return (
    <Create {...props} redirect="show">
      <SimpleForm>
        <TextInput source="name" fullWidth label="Titolo" />

        <TextInput source="description" fullWidth label="Descrizione" multiline minRows={5} />
      </SimpleForm>
    </Create>
  )
}
