import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin"
import { AvatarField } from "../../../atoms/AvatarField"
import { Audiotrack, Videocam } from "@mui/icons-material"

export const MultimediaList = (props: ListProps) => {
  return (
    <List
      {...props}
      title="Lista di Contenuti"
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ isDeleted: false }}
      filters={
        <Filter {...props}>
          <SearchInput source="q" alwaysOn />
          <ReferenceInput source="category_ID" reference="Categories" alwaysOn>
            <SelectInput color="secondary" optionText="name" fullWidth label="Categoria" />
          </ReferenceInput>
          <SelectInput
            source="type"
            fullWidth
            alwaysOn
            label="Tipologia"
            choices={[
              { name: "Video", id: "video" },
              { name: "Audio", id: "audio" },
            ]}
          />
          <SelectInput
            source="isDeleted"
            fullWidth
            alwaysOn
            label="Mostra eliminati"
            choices={[
              { name: "Si", id: true },
              { name: "No", id: false },
            ]}
          />
        </Filter>
      }
      exporter={false}
    >
      <Datagrid
        rowClick="show"
        rowStyle={(record: Record<string, any>, index: number) => ({
          backgroundColor: record.isDeleted ? "#c4c4c4" : "white",
          textDecoration: record.isDeleted ? "line-through" : "none",
        })}
        isRowSelectable={(record) => !record.isDeleted}
      >
        <AvatarField />
        <TextField source="title" label="Titolo" />
        <FunctionField
          render={(record: any) => (record.type === "audio" ? <Audiotrack /> : <Videocam />)}
          label="Tipologia"
        />
        <DateField showTime source="createdAt" label="Data" />
        <ReferenceField source="category_ID" reference="Categories" link="show" label="Categoria">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
