import { Admin, Resource } from "react-admin"
import authProvider from "./providers/authProvider"
import dataProvider from "./providers/dataProvider"
import polyglotI18nProvider from "ra-i18n-polyglot"
import { italianMessages } from "./i18n/it"
import LoginPage from "./components/organisms/Login"
import Dashboard from "./components/organisms/Dashboard"
import CustomTheme from "./components/organisms/Layout/theme"
import CustomLayout from "./components/organisms/Layout"

import { AccountBox, Article, AudioFile, Headphones, List, Recommend } from "@mui/icons-material"
import { ClientList, ClientShow } from "./components/resources/client"
import { ReactionList, ReactionShow } from "./components/resources/reaction"
import {
  MultimediaCreate,
  MultimediaEdit,
  MultimediaList,
  MultimediaShow,
} from "./components/resources/multimedia"
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "./components/resources/category"
import { PlaySessionList, PlaySessionShow } from "./components/resources/playSession"

const i18nProvider = polyglotI18nProvider(italianMessages, "it")

const App = () => (
  <Admin
    title="Archeide Console"
    loginPage={LoginPage}
    dashboard={Dashboard}
    theme={CustomTheme}
    layout={CustomLayout}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider("https://api.archeide.cluster.jetop.com/api")}
    authProvider={authProvider("https://api.archeide.cluster.jetop.com/api")}
  >
    <Resource
      name="Clients"
      icon={AccountBox}
      options={{ label: "Clienti" }}
      list={ClientList}
      show={ClientShow}
    />
    <Resource
      name="Reactions"
      icon={Recommend}
      options={{ label: "Reazioni" }}
      list={ReactionList}
      show={ReactionShow}
    />
    <Resource
      name="Multimedia"
      icon={AudioFile}
      options={{ label: "Podcast / Video" }}
      list={MultimediaList}
      show={MultimediaShow}
      create={MultimediaCreate}
      edit={MultimediaEdit}
    />
    <Resource
      name="Categories"
      icon={List}
      options={{ label: "Categorie" }}
      list={CategoryList}
      edit={CategoryEdit}
      show={CategoryShow}
      create={CategoryCreate}
    />
    <Resource
      name="PlaySessions"
      icon={Headphones}
      options={{ label: "Sessioni di ascolto" }}
      list={PlaySessionList}
      show={PlaySessionShow}
    />
    <Resource name="Articles" icon={Article} options={{ label: "Articoli" }} />
  </Admin>
)

export default App
