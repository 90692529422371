import {
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  Labeled,
  Pagination,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import Title from "../../../atoms/Title"

export const MultimediaShow = (props: ShowProps) => {
  return (
    <Show {...props} title={<Title property="title" title="Contenuto" />}>
      <TabbedShowLayout>
        <Tab label="Informazioni">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <TextField source="title" label="Titolo" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <TextField source="type" label="Tipologia" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
              <SimpleShowLayout>
                <DateField showTime source="createdAt" label="Data" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <ReferenceField
                  source="category_ID"
                  reference="Categories"
                  link="show"
                  label="Categoria"
                >
                  <TextField source="name" />
                </ReferenceField>
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <ReferenceField
                  source="wordpress_ID"
                  reference="Articles"
                  link="show"
                  label="Articolo collegato"
                >
                  <TextField source="title.rendered" emptyText="-" />
                </ReferenceField>
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
              <SimpleShowLayout>
                <TextField source="description" label="Descrizione" />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="Foto">
          <SimpleShowLayout>
            <ImageField
              source="imageUrl"
              label="Copertina"
              sx={{
                "& .RaImageField-image": {
                  width: 800,
                  height: "auto",
                },
              }}
            />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Contenuto">
          <SimpleShowLayout>
            <MediaFile />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Analisi">
          <DataRecap />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
const DataRecap = () => {
  const record = useRecordContext()
  const { isLoading: reactionLoading, total: totalReactions } = useGetList("Reactions", {
    pagination: {
      perPage: 5000,
      page: 1,
    },
    sort: {
      field: "date",
      order: "DESC",
    },
    filter: {
      multimedia_ID: record.id,
    },
  })
  const { isLoading: sessionLoading, total: totalSessions } = useGetList("PlaySessions", {
    pagination: {
      perPage: 5000,
      page: 1,
    },
    sort: {
      field: "startDate",
      order: "DESC",
    },
    filter: {
      multimedia_ID: record.id,
    },
  })
  return (
    <Grid container spacing="1em">
      <Grid item xs={6}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" component="div" sx={{ marginRight: "1em" }}>
              {!sessionLoading ? totalSessions : "-"}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: "0.5em" }}>
              Sessioni di ascolto presenti
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReferenceManyField
              record={record}
              reference="PlaySessions"
              target="multimedia_ID"
              label={false}
              sort={{ field: "startDate", order: "DESC" }}
              pagination={<Pagination />}
            >
              <Datagrid rowClick="show">
                <DateField label="Data" source="startDate" showTime />
                <ReferenceField label="Cliente" source="client_ID" reference="Clients" link="show">
                  <FunctionField
                    label="Nome"
                    render={(record: any) => `${record["name"]} ${record["surname"]}`}
                  />
                </ReferenceField>
                <FunctionField
                  render={(record: RaRecord) => record.startPoint + " - " + record.endPoint}
                  label="Tempistiche"
                />
                <TextField source="status" label="Evento" />
              </Datagrid>
            </ReferenceManyField>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={6}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" component="div" sx={{ marginRight: "1em" }}>
              {!reactionLoading ? totalReactions : "-"}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: "0.5em" }}>
              Reazioni presenti
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReferenceManyField
              record={record}
              reference="Reactions"
              target="multimedia_ID"
              label={false}
              sort={{ field: "date", order: "DESC" }}
              pagination={<Pagination />}
            >
              <Datagrid rowClick="show">
                <DateField label="Data" source="date" showTime />
                <ReferenceField label="Cliente" source="client_ID" reference="Clients" link="show">
                  <FunctionField
                    label="Nome"
                    render={(record: any) => `${record["name"]} ${record["surname"]}`}
                  />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}
const MediaFile = () => {
  const record = useRecordContext()
  return (
    <Labeled label="File">
      {record.type === "audio" ? (
        <audio controls>
          <source
            src={`https://archeide-app-multimedia.s3.eu-central-1.amazonaws.com/${record.aws_file_key}`}
          />
        </audio>
      ) : (
        <video width="800" controls>
          <source
            src={`https://archeide-app-multimedia.s3.eu-central-1.amazonaws.com/${record.aws_file_key}`}
          />
        </video>
      )}
    </Labeled>
  )
}
